import React, { useState } from 'react';
import i18next from 'i18next';
import { Card, Header } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/ar';
import { Styles, Theme } from '../../res';

const { noExtraSpaces, f_16_700_text, f_12_700_text, f_10_400_text } = Styles;

const CalendarCard = ({ isMobile, month, onSelectCourse }) => {

    const { t } = useTranslation();
    const [selected, setSelected] = useState(false);
    const isDisabled = !month.courses.length;

    return (
        <Card
            style={{
                paddingTop: selected ? 0 : 30, paddingBottom: selected ? 0 : 30,
                cursor: 'pointer',
                flex: isMobile ? 'none' : '0 0 15%',
                borderRadius: 8, boxShadow: 'none', margin: 5,
                backgroundColor: isDisabled ? Theme.colors.GREY_10 : selected ? Theme.colors.SECONDARY_50 : Theme.colors.WHITE
            }}
            onMouseEnter={() => isDisabled ? undefined : setSelected(true)}
            onMouseLeave={() => isDisabled ? undefined : setSelected(false)}
        >
            {!selected && <Card.Content>
                <Header style={{ ...noExtraSpaces, ...f_16_700_text, color: isDisabled ? Theme.colors.GREY_50 : Theme.colors.BLACK }}>{moment(month.month).locale(i18next.language).format('MMMM')}</Header>
                <Header style={{ ...noExtraSpaces, ...f_12_700_text, color: isDisabled ? Theme.colors.GREY_70 : Theme.colors.PRIMARY_50 }}> {isDisabled ? t('NoCourses') : `${month.courses.length} ${t('Courses')}`}</Header>
            </Card.Content>}

            {
                selected && <Card.Content onClick={(e) => onSelectCourse(e, null, month)}>
                    <Header style={{ ...noExtraSpaces, ...f_12_700_text, color: Theme.colors.WHITE }}> {`${moment(month.month).locale(i18next.language).format('MMMM')} ${t('Courses')}`}</Header>
                    {
                        month.courses.slice(0, 3).map((c, index) => <p key={index} style={{ ...f_10_400_text, color: Theme.colors.WHITE, whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: 'auto', overflow: 'hidden' }}> {c.courseName}</p>)
                    }
                    <Header style={{ ...noExtraSpaces, ...f_12_700_text, color: Theme.colors.PRIMARY_50 }}>{t('ViewAll')}</Header>
                </Card.Content>
            }
        </Card >
    );
};

export default CalendarCard;
import i18n from 'i18next';
import {
    ADMIN_GET_ALL_TOPICS_OPERATION_START,
    ADMIN_GET_ALL_TOPICS_OPERATION_FINISH,
    ADMIN_GET_ALL_TOPICS_OPERATION_FAIL,
    ADMIN_GET_ALL_TOPICS,
    ADMIN_GET_BOOK_DETAILS_OPERATION_START,
    ADMIN_GET_BOOK_DETAILS_OPERATION_FINISH,
    ADMIN_GET_BOOK_DETAILS_OPERATION_FAIL,
    ADMIN_GET_BOOK_DETAILS,
    ADMIN_GET_VIDEO_DETAILS_OPERATION_START,
    ADMIN_GET_VIDEO_DETAILS_OPERATION_FINISH,
    ADMIN_GET_VIDEO_DETAILS_OPERATION_FAIL,
    ADMIN_GET_VIDEO_DETAILS,
    ADMIN_POST_NEW_BOOK_DETAILS,
    ADMIN_DELETE_BOOK_DETAILS,
    ADMIN_DELETE_VIDEO_DETAILS,

} from '../types';
import request from '../../api';

export const adminGetAllTopics = () => async dispatch => {
    dispatch({ type: ADMIN_GET_ALL_TOPICS_OPERATION_START });
    try {
        const { data: { topics } } = await request.get('/topics/fullinfo');
        dispatch({ type: ADMIN_GET_ALL_TOPICS, payload: topics });
    } catch (err) {
        if (err.response) dispatch({ type: ADMIN_GET_ALL_TOPICS_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: ADMIN_GET_ALL_TOPICS_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: ADMIN_GET_ALL_TOPICS_OPERATION_FINISH });
    }
};

export const adminGetVideoDetails = (topicId) => async dispatch => {
    dispatch({ type: ADMIN_GET_VIDEO_DETAILS_OPERATION_START });
    try {
        const { data: { data } } = await request.get(`/videos/fullinfo/topic/${topicId}`);
        dispatch({ type: ADMIN_GET_VIDEO_DETAILS, payload: data });
    } catch (err) {
        if (err.response) dispatch({ type: ADMIN_GET_VIDEO_DETAILS_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: ADMIN_GET_VIDEO_DETAILS_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: ADMIN_GET_VIDEO_DETAILS_OPERATION_FINISH });
    }
};

export const adminGetBookDetails = (topicId) => async dispatch => {
    dispatch({ type: ADMIN_GET_BOOK_DETAILS_OPERATION_START });
    try {
        const { data: { data } } = await request.get(`/books/fullinfo/topic/${topicId}`);
        dispatch({ type: ADMIN_GET_BOOK_DETAILS, payload: data[0] });
    } catch (err) {
        if (err.response) dispatch({ type: ADMIN_GET_BOOK_DETAILS_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: ADMIN_GET_BOOK_DETAILS_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: ADMIN_GET_BOOK_DETAILS_OPERATION_FINISH });
    }
};

export const adminPostNewVideoDetails = (details) => async dispatch => {
    dispatch({ type: ADMIN_GET_VIDEO_DETAILS_OPERATION_START });
    try {
        await request.post('/video/new', details);
        await adminGetVideoDetails(details.topicId)(dispatch);
    } catch (err) {
        if (err.response) dispatch({ type: ADMIN_GET_VIDEO_DETAILS_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: ADMIN_GET_VIDEO_DETAILS_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: ADMIN_GET_VIDEO_DETAILS_OPERATION_FINISH });
    }
};

export const adminDeleteVideoDetails = (videoId, topicId) => async dispatch => {
    dispatch({ type: ADMIN_GET_VIDEO_DETAILS_OPERATION_START });
    try {
        await request.delete('/video/topic', { data: { videoId, topicId } });
        await adminGetVideoDetails(topicId)(dispatch);
        dispatch({ type: ADMIN_DELETE_VIDEO_DETAILS });
    } catch (err) {
        if (err.response) dispatch({ type: ADMIN_GET_VIDEO_DETAILS_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: ADMIN_GET_VIDEO_DETAILS_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: ADMIN_GET_VIDEO_DETAILS_OPERATION_FINISH });
    }
};

export const adminUpdateVideoDetails = (videoId, isActive, topicId, instructorId = null) => async dispatch => {
    dispatch({ type: ADMIN_GET_VIDEO_DETAILS_OPERATION_START });
    try {
        await request.post('/video/update', { videoId, isActive, instructorId });
        await adminGetVideoDetails(topicId)(dispatch);
        dispatch({ type: ADMIN_DELETE_VIDEO_DETAILS });
    } catch (err) {
        if (err.response) dispatch({ type: ADMIN_GET_VIDEO_DETAILS_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: ADMIN_GET_VIDEO_DETAILS_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: ADMIN_GET_VIDEO_DETAILS_OPERATION_FINISH });
    }
};

export const adminPostNewBookDetails = (details) => async dispatch => {
    dispatch({ type: ADMIN_GET_BOOK_DETAILS_OPERATION_START });
    try {
        await request.post('/book/new', details);
        await adminGetBookDetails(details.topicId)(dispatch);
        dispatch({ type: ADMIN_POST_NEW_BOOK_DETAILS });
    } catch (err) {
        if (err.response) dispatch({ type: ADMIN_GET_BOOK_DETAILS_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: ADMIN_GET_BOOK_DETAILS_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: ADMIN_GET_BOOK_DETAILS_OPERATION_FINISH });
    }
};


export const adminDeleteBookDetails = (bookId, topicId) => async dispatch => {
    dispatch({ type: ADMIN_GET_BOOK_DETAILS_OPERATION_START });
    try {
        await request.delete('/book/topic', { data: { topicId, bookId } });
        dispatch({ type: ADMIN_DELETE_BOOK_DETAILS });
    } catch (err) {
        if (err.response) dispatch({ type: ADMIN_GET_BOOK_DETAILS_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: ADMIN_GET_BOOK_DETAILS_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: ADMIN_GET_BOOK_DETAILS_OPERATION_FINISH });
    }
};
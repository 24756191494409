import __ from 'lodash';
import React, { useState, useRef } from 'react';
import { Button, Divider, Header, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import DataGrid, { Column, Editing, Form } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/ui/form';

const SelectorSection = ({ data, saveSection, deleteSection, cloneSection, moveSectionDown, moveSectionUp, sectionIndex }) => {
    const { t } = useTranslation();
    const gridRef = useRef();
    const cGridRef = useRef();
    const [options] = useState(data?.options || []);
    const [correctAnswers] = useState(data?.correctAnswers || []);


    return (
        <Segment basic>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Header icon="list ul" content={`${t("Section")} #${data.order}`} />
                <div>
                    <Button icon='save' positive onClick={() => saveSection(data.sectionId, { ...data, options: gridRef.current._instance.getDataSource()._items, correctAnswers: cGridRef.current._instance.getDataSource()._items })} />
                    <Button icon='copy' positive onClick={() => cloneSection({
                        ...data,
                        options: gridRef.current._instance.getDataSource()._items.map(i => ({ ...i, __KEY__: `${__.uniqueId('option')}_${new Date().getTime()}` })),
                        correctAnswers: cGridRef.current._instance.getDataSource()._items.map(i => ({ ...i, __KEY__: `${__.uniqueId('cAnswer')}_${new Date().getTime()}` })),
                    })} />
                    <Button icon='trash' negative onClick={() => deleteSection(data.sectionId)} />
                    <Button title={t("Up")} size='small' icon='arrow up' color='blue' onClick={() => moveSectionUp(sectionIndex)} />
                    <Button title={t("Down")} size='small' icon='arrow down' color='blue' onClick={() => moveSectionDown(sectionIndex)} />

                </div>
            </div>
            <Divider />
            <Segment>
                <Header>{t("ChoicesManagement")}</Header>
                <DataGrid
                    ref={gridRef}
                    dataSource={options}
                    showBorders
                    columnHidingEnabled
                    wordWrapEnabled
                    columnAutoWidth
                >
                    <Editing
                        mode='row'
                        useIcons
                        allowAdding
                        allowUpdating
                        allowDeleting
                    >
                        <Form>
                            <Item itemType="group" colCount={2} colSpan={2}>
                                <Item isRequired dataField="nameEn" />
                                <Item isRequired dataField="nameAr" />
                                <Item isRequired dataField="fieldsCount" />
                                <Item dataField="correctAnswer" />
                            </Item>
                        </Form>
                    </Editing>

                    <Column dataField="nameEn" caption={t('EnglishName')} />
                    <Column dataField="nameAr" caption={t('ArabicName')} />
                    <Column dataField="fieldsCount" dataType="number" caption={t('FieldsCount')} />
                    <Column
                        caption={t('Correct')}
                        dataType="boolean"
                        dataField='correctAnswer'
                    />
                </DataGrid>
            </Segment>
            <Segment basic>
                <Header>{t("CorrectAnswers")}</Header>
                <DataGrid
                    ref={cGridRef}
                    dataSource={correctAnswers}
                    showBorders
                    columnHidingEnabled
                    wordWrapEnabled
                    columnAutoWidth
                >
                    <Editing
                        mode='row'
                        useIcons
                        allowAdding
                        allowUpdating
                        allowDeleting
                    >
                        <Form>
                            <Item itemType="group" colCount={2} colSpan={2}>
                                <Item dataField="correctAnswer" />
                            </Item>
                        </Form>
                    </Editing>

                    <Column dataField="answer" caption={t('Answer')} />
                </DataGrid>
            </Segment>
        </Segment>
    );
};

export default SelectorSection;
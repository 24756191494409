import React from 'react';

const noCopyPaste = (e) => {
    e.preventDefault()
    return false;
};

const NoCopyPaste = ({ children }) => {
    return (
        <div unselectable="on" onCopy={noCopyPaste} onPaste={noCopyPaste} onDrag={noCopyPaste} onDrop={noCopyPaste} onSelect={noCopyPaste} className='noCopyPaste'>
            {children}
        </div>
    );
};

export default NoCopyPaste;
import React, { useRef } from 'react';
import { Icon } from 'semantic-ui-react';
import { Workbook } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css";

const transformData = (responseData) => {
    return responseData?.map((sheet) => ({
        id: sheet.id,
        name: sheet.name,
        celldata: sheet?.data?.flatMap((row, rIndex) =>
            row?.map((cell, cIndex) => {
                if (cell !== null) {
                    return {
                        r: rIndex,
                        c: cIndex,
                        v: cell,
                    };
                }

                return undefined;
            })
                ?.filter((cell) => cell !== undefined),
        ),
        calcChain: sheet?.calcChain?.map((item) => {
            const relatedCell = sheet.data[item.r][item.c];
            return {
                r: item.r,
                c: item.c,
                id: item.id,
                v: relatedCell !== null ? relatedCell : null,
            };
        }),
    }));
};


const SpreadsheetEditor = ({ data, onBlur = () => { }, readOnly }) => {
    const ref = useRef();

    return (
        <div id="parentSheet" style={{ width: "100%", height: "100%" }}>
            <Workbook
                ref={ref}
                data={data?.value ? transformData(data?.value) : [{ name: 'Sheet1', celldata: [{ r: 0, c: 0, v: null }] }]}
                allowEdit={!readOnly}
                column={26}
                row={36}
                defaultColWidth={150}
                customToolbarItems={readOnly ? [] : [{ key: "saveme", tooltip: "Save", onClick: onBlur, icon: <div style={{ marginLeft: 'auto' }}><Icon name='save' /></div> }]}
                lang="en"
                onChange={data?.setValue}
            />
        </div>
    );
};

export default SpreadsheetEditor;
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ErrorSegment } from '../../components';
import { withTranslation } from 'react-i18next';
import PATHS from '../../Routers/PATHS';

export default (ChildComponent, requiredResources = [], requiredPermissions = ['READ'], requiredPermissionsOperator = 'OR') => {

    const RequireAuth = (props) => {

        if (!props.user) return <Redirect to={PATHS.SIGNIN.URL} />;

        const PERMISSIONS = {
            ADD: false,
            UPDATE: false,
            DELETE: false,
            READ: false
        };

        requiredResources.forEach(requiredResource => {
            const ac = props.user.ac[requiredResource] || {};
            PERMISSIONS.ADD = ac.ADD || PERMISSIONS.ADD;
            PERMISSIONS.UPDATE = ac.EDIT || PERMISSIONS.UPDATE;
            PERMISSIONS.DELETE = ac.DELETE || PERMISSIONS.DELETE;
            PERMISSIONS.READ = ac.READ || PERMISSIONS.READ;
        });

        const allowness = requiredPermissions.map(e => PERMISSIONS[e]);
        let lockKey = false;
        if (requiredPermissionsOperator === 'OR')
            lockKey = allowness.some(e => e === true);
        else if (requiredPermissionsOperator === 'AND')
            lockKey = allowness.every(e => e === true);

        if (lockKey)
            return <ChildComponent {...props} PERMISSIONS={PERMISSIONS} />;
        else
            return <ErrorSegment text={props.t('NotAllowed')} icon='lock' />;

    };

    const mapStateToProps = ({ auth }) => {
        const { user } = auth;
        return { user };
    };

    return withTranslation()(connect(mapStateToProps, {})(RequireAuth));
};
import React, { useEffect, useState } from 'react';
import { Segment, Modal, Icon } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import Draggable from 'react-draggable'; // The default
import request from '../../../api';

const QuestionTranslationModal = ({ lang, question, examRoundId, show, setShow, t }) => {
    const [questionHeader, setQuestionHeader] = useState('');
    const [questionBody, setQuestionBody] = useState('');

    useEffect(() => {
        setQuestionHeader('');
        setQuestionBody('');
        if (show && question) {
            request.get(`/student/round/exam/questions/${examRoundId}?questionId=${question.questionId}&lang=${lang}`)
                .then(d => {
                    request.get(`/student/question/${question.questionId}/options?lang=${lang}`)
                        .then(
                            qd => {
                                const alphArray = lang === 'ar' ? ['أ', 'ب', 'جـ', 'د'] : ['A', 'B', 'C', 'D'];
                                let body = '';
                                let options = qd.data.data;
                                let index = 0;

                                for (const element of question.options) {
                                    let item = options.find(x => x.id === element.id);
                                    body += `<div>${alphArray[index++]}. ${item.contents[0].content}</div>`;
                                }

                                setQuestionHeader(d.data.data[0].questionText);
                                setQuestionBody(body);
                                document.body.classList.remove('dimmable');
                                document.body.classList.remove('dimmed');
                            }
                        );

                });
        }
        //eslint-disable-next-line
    }, [show]);

    return (
        <Draggable>
            <Modal
                style={{ textAlign: lang === 'en' ? 'left' : 'right', margin: '5px' }}
                open={show}
                content={
                    <Segment basic dangerouslySetInnerHTML={{ __html: questionBody }}></Segment>
                }
                onClose={() => setShow(false)}
                closeIcon={<Icon name='close' color='black' />}
                dimmer={{ style: { backgroundColor: 'transparent' } }}
                size='small'
                centered
                header={
                    <Segment basic dangerouslySetInnerHTML={{ __html: questionHeader }}>
                    </Segment>
                }
                closeOnDimmerClick={false}
            />
        </Draggable>
    );
};

export default withTranslation()(QuestionTranslationModal);
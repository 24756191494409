import React from 'react';
import { Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { HtmlEditor } from 'devextreme-react/ui/html-editor';

const TextQuestionTemplate = ({ textAr, textEn }) => {
    const { i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    return (
        <Segment basic>
            <HtmlEditor value={isArabic ? textAr?.trim() : textEn?.trim()} readOnly style={{ border: 'none', boxShadow: 'none' }} className='resizo' />
        </Segment>
    );
};


export default TextQuestionTemplate;